<template>

	<div v-show="!loaded" class="justify-content-center text-center flex-wrap">
		<ProgressSpinner/>
		<h5>Loading Data ...</h5>
	</div>

	<div class="grid" v-show="loaded">
		<div class="col-12 lg:col-6 xl:col-12">
			<PartnerSelect class="mr-3" />
		</div>
		<div class="col-12 lg:col-6 xl:col-3">
			<div class="card mb-0">
				<div class="flex justify-content-between mb-3">
					<div>
						<span class="block text-500 font-medium mb-3">No. Overall Completed</span>
						<div class="text-900 font-medium text-xl">{{overall_total}}</div>
					</div>
				</div>
				<span class="text-green-500 font-medium">{{weekly_completed}}  </span>
				<span class="text-500"> this Week</span>
				<br>
				<span class="text-green-500 font-medium">{{daily_completed}}  </span>
				<span class="text-500"> today</span>
			</div>
		</div>
		<div class="col-12 lg:col-6 xl:col-3">
			<div class="card mb-0">
				<div class="flex justify-content-between mb-3">
					<div>
						<span class="block text-500 font-medium mb-3">No. Questionnaires Completed</span>
						<div class="text-900 font-medium text-xl">{{questionnaires_total}}</div>
					</div>
				</div>
				<span class="text-green-500 font-medium">{{questionnaires_weekly}}  </span>
				<span class="text-500"> this Week</span>
				<br>
				<span class="text-green-500 font-medium">{{questionnaires_daily}}  </span>
				<span class="text-500"> today</span>
			</div>
		</div>
		<div class="col-12 lg:col-6 xl:col-3">
			<div class="card mb-0">
				<div class="flex justify-content-between mb-3">
					<div>
						<span class="block text-500 font-medium mb-3">No. Surveys Completed</span>
						<div class="text-900 font-medium text-xl">{{surveys_total}}</div>
					</div>
				</div>
				<span class="text-green-500 font-medium">{{surveys_weekly}}  </span>
				<span class="text-500"> this Week</span>
				<br>
				<span class="text-green-500 font-medium">{{surveys_daily}}  </span>
				<span class="text-500"> today</span>
			</div>
		</div>
		<div class="col-12 lg:col-6 xl:col-3">
			<div class="card mb-0">
				<div class="flex justify-content-between mb-3">
					<div>
						<span class="block text-500 font-medium mb-3">No. Courses Completed</span>
						<div class="text-900 font-medium text-xl">{{courses_total}}</div>
					</div>
				</div>
				<span class="text-green-500 font-medium">{{courses_weekly}}  </span>
				<span class="text-500"> this Week</span>
				<br>
				<span class="text-green-500 font-medium">{{courses_daily}}  </span>
				<span class="text-500"> today</span>
			</div>
		</div>


	<div class="col-12 xl:col-12">
		<div class="card">
			<div class="flex align-items-center justify-content-between mb-4">
				<h5>Feed</h5>
			</div>
			<ul class="p-0 mx-0 mt-0 mb-4 list-none">
				<ScrollPanel style="width: 100%; height: 200px">
				<li v-for="subscription in subscriptions" :key="subscription.id" class="flex align-items-center py-2 border-bottom-1 surface-border">
					<div v-if="!subscription.user?.profile_picture" class="w-3rem h-3rem flex align-items-center justify-content-center bg-blue-100 border-circle mr-3 flex-shrink-0">
					</div>
					<img class="w-3rem h-3rem flex align-items-center justify-content-center border-circle mr-3 flex-shrink-0" v-if="subscription.user?.profile_picture" v-bind:src="'https://zltoexchange.s3.amazonaws.com/media/' + subscription.user?.profile_picture ">
					<div >
					<span class="text-900 line-height-3">
						<span class="text-700 text-yellow-500"> {{subscription.user?.name}} {{subscription.user?.surname}} </span>
						<span class="text-700">  completed <span class="text-blue-500" >{{subscription.questionnaire?.title}} </span></span> 
                        <span class="text-grey-500">{{timeSince(subscription.timestamp)}} </span>
					</span>
					</div>
				</li>
				</ScrollPanel>
			</ul>
		</div>
	</div>
	<div class="col-12 xl:col-12">
		<div class="card">
			<div id="map" ref="map" style="width: 100%; height: 500px;">
			</div>
		</div>
	</div>
	<div class="col-12 xl:col-12">
		<div class="card">
			<h5>Trends</h5>
			<Chart type="line" :data="basicData" />
			<Dropdown v-model="selectedYear" :options="years" optionLabel="name" placeholder="Select a Year" />
		</div>
	</div>
</div>
</template>

<script>
import EventBus from '@/AppEventBus';
import QuestionnaireService from '../service/QuestionnaireService';
import { store } from '../store/store.js'
import { DateHelper } from '../util/date_helper.js'
import AuthEventBus from "../common/AuthEventBus";
import PartnerSelect from './PartnerDropdown';

export default {
    components: {
		PartnerSelect
    },
	data() { 
		return {
			openedMarkerID: null,
			items: [
                {label: 'Add New', icon: 'pi pi-fw pi-plus'},
                {label: 'Remove', icon: 'pi pi-fw pi-minus'}
            ],
			lineOptions: null,
			loaded:false,

			subscriptions: [],

			surveys_total: 0,
			surveys_weekly: 0,
			surveys_daily: 0,

			courses_total: 0,
			courses_weekly: 0,
			courses_daily: 0,

			questionnaires_total: 0,
			questionnaires_weekly: 0,
			questionnaires_daily: 0,

			weekly_completed: 0,
			daily_completed: 0,
			mapMarkers: [],
			heatMapMarkers: [],
			map:null,

			overall_total: 0,

			basicData:null,

			selectedYear: null,

			years: [
				{name: '2023'},
				{name: '2022'},
				{name: '2021'},
				{name: '2020'},
				{name: '2019'}
			]
		}
	},

	productService: null,
	questionnaireService:null,
	themeChangeListener: null,
    chartData: null,
	marker: null,
	

	mounted() {
		this.getQuestionnaireSubscriptions(localStorage.getItem('partner'))
		this.getCompletedUsers(localStorage.getItem('partner'))
		this.getTimeData(localStorage.getItem('partner'))
	
		this.themeChangeListener = (event) => {
            if (event.dark)
                this.applyDarkTheme();
            else
                this.applyLightTheme();
        };
        EventBus.on('change-theme', this.themeChangeListener);

		if (this.isDarkTheme()) {
            this.applyDarkTheme();
        }
        else {
            this.applyLightTheme();
        }
	},
	computed:{
		getPartner(){
			return store.partner
		},
		getSelectedYear(){
			console.log("@@@@ year", this.selectedYear)
			return this.selectedYear
		},
	},

    watch: {
		getPartner (newVal) {
			console.log(`New val${newVal} yay!`)
			this.getQuestionnaireSubscriptions(newVal)
			this.getCompletedUsers(newVal)
			this.getTimeData(newVal)
    },
	getSelectedYear(newVal) {
			console.log("@year", newVal)

			if (newVal != null) {
				this.getTimeData(localStorage.getItem('partner'), newVal.name)
			}
			else {
				this.getTimeData(localStorage.getItem('partner'))
			}
		}

	},
	beforeUnmount() {
        EventBus.off('change-theme', this.themeChangeListener );
    },
	created() {
		this.questionnaireService = new QuestionnaireService();
	},
	methods: {

		infoDetail(name, title){
			return `${name} completed ${title}`
		},
		initMap(){

			console.log('refs', this.$refs['map'])

			this.map = new window.google.maps.Map(this.$refs['map'], {
				center: {lat: -28.4792625, lng: 24.6727135},
				zoom: 5,
			});

			for (var x of this.mapMarkers){
					const marker = new window.google.maps.Marker({
					position: { lat: x.coords.lat, lng: x.coords.lng },
					map: this.map,
					title: 'user'
				})


					const infowindow = new window.google.maps.InfoWindow({
					content: this.infoDetail(x.user, x.title),
					ariaLabel: "User",
				});

				marker.addListener("click", () => {
					infowindow.open({
						anchor: marker,
						map: this.map});
				});

			}

			var heatmap = new window.google.maps.visualization.HeatmapLayer({
				data: this.heatMapMarkers.filter(Boolean)
			});
			heatmap.setMap(this.map);
			heatmap.set("radius", 20);
			heatmap.set("dissipating", true);
			heatmap.set("maxIntensity", 20);
			this.loaded = true

		},

		getQuestionnaireSubscriptions(id){
			this.questionnaireService.getQuestionnnaireSubscriptions(id).then(data => {
				console.log("@@ data", data.data)

				this.subscriptions = data.data.questionnaire_subscriptions.slice(0,50)

				this.overall_total = data.data.questionnaire_subscriptions.length
                this.courses_total = data.data.questionnaire_subscriptions.filter((x) => x.questionnaire?.questionnaire_type == 1).length + data.data.questionnaire_subscriptions.filter((x) => x.questionnaire?.questionnaire_type == 3).length
                this.questionnaires_total = data.data.questionnaire_subscriptions.filter((x) => x.questionnaire?.questionnaire_type == 2).length
                this.surveys_total = data.data.questionnaire_subscriptions.filter((x) => x.questionnaire?.questionnaire_type == 4 ).length

				var weekly_subscriptions = data.data.questionnaire_subscriptions.filter((item) => {
					var date = new Date(item.timestamp).toLocaleDateString("en-GB")
					return date >= DateHelper.weekly_date().firstday &&
					date <= DateHelper.weekly_date().lastday;
				});

				this.weekly_completed = weekly_subscriptions.length
				this.courses_weekly = weekly_subscriptions.filter((x) => x.questionnaire?.questionnaire_type == 1).length
				this.questionnaires_weekly = weekly_subscriptions.filter((x) => x.questionnaire?.questionnaire_type == 2 || x.questionnaire?.questionnaire_type == 3).length
                this.surveys_weekly = weekly_subscriptions.filter((x) => x.questionnaire?.questionnaire_type == 4).length

				var daily_subscriptions = data.data.questionnaire_subscriptions.filter((item) => {
					var date = new Date(item.timestamp).toLocaleDateString("en-GB")
					return date == DateHelper.today() 
				});

				this.daily_completed = daily_subscriptions.length
				this.courses_daily = daily_subscriptions.filter((x) => x.questionnaire?.questionnaire_type == 1).length
				this.questionnaires_daily = daily_subscriptions.filter((x) => x.questionnaire?.questionnaire_type == 2 || x.questionnaire?.questionnaire_type == 3).length
                this.surveys_daily = daily_subscriptions.filter((x) => x.questionnaire?.questionnaire_type == 4).length

				console.log("@@@loaded", this.loaded)
				},
			error => {
				this.content = 
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
				
				if (error.response && error.response.status === 403) {
					console.log('@@@@, error', error)
					AuthEventBus.dispatch("logout");
				}
			})
		},
        timeSince(date) {
            
            var formatted_date = new Date(date)

            var seconds = Math.floor(((new Date().getTime()/1000) - formatted_date.getTime()/1000))

            var interval = seconds / 86400;

            if (interval >= 1 && interval < 2 ) {
                return Math.floor(interval) + " day ago.";
            }
            else if (interval > 2 ) {
                return Math.floor(interval) + " days ago.";
            }
            else if (interval < 1 ) {
                return " recently.";
            }
        },

		getCompletedUsers(id){

			this.questionnaireService.getCompletedUsers(id).then(data => {
				console.log("@@ data", data.data)

				for (var x of data.data?.completed_users){
					if(x.location_coordinates != null){
						
						var coords = x.location_coordinates.split(',')
						coords = {'lat': parseFloat(coords[0]), 'lng': parseFloat((coords[1]))}
						var username = x.name + ' ' + x.surname
						if(!Number.isNaN(coords.lat) || !Number.isNaN(coords.lng)){
							this.mapMarkers.push({'user':username, 'title': x.title, 'coords': coords})
							
							this.heatMapMarkers.push(new window.google.maps.LatLng(coords.lat, coords.lng),)
						}
					}
				}
				console.log("this.mapMarkers", this.mapMarkers)

				this.initMap();
				this.loaded = true
			},
			error => {
				this.content = 
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
				
				if (error.response && error.response.status === 403) {
					console.log('@@@@, error', error)
					AuthEventBus.dispatch("logout");
				}
			})
		},

		getTimeData(id, year){
			this.questionnaireService.getTimeData(id, year).then(data => {
				console.log("@@ timedata", data.data)

				var value_data = []
				var label_data = []

				const month = [" ", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
				// const options = { day: 'numeric', month: 'long', year: 'numeric'};

				for (var x of data.data.permonth){

					var name = month[x.month]

					value_data.push(x.total)
					label_data.push(name)
				}

				this.basicData = {
					labels: label_data,
					datasets: [
						{
							label: 'Total',
							data: value_data,
							fill: false,
							borderColor: '#42A5F5',
							tension: .4
						}
					]
				}
			},
			error => {
				this.content = 
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
				
				if (error.response && error.response.status === 403) {
					console.log('@@@@, error', error)
					AuthEventBus.dispatch("logout");
				}
			})
		},

		openMarker(id) {
			this.openedMarkerID = id
		},
		isDarkTheme() {
            return this.$appState.darkTheme === true;
        },
		applyLightTheme() {
			this.lineOptions = {
				plugins: {
					legend: {
						labels: {
							color: '#495057'
						}
					}
				},
				scales: {
					x: {
						ticks: {
							color: '#495057'
						},
						grid: {
							color:  '#ebedef',
						}
					},
					y: {
						ticks: {
							color: '#495057'
						},
						grid: {
							color:  '#ebedef',
						}
					},
				}
			};
		},
		applyDarkTheme() {
			this.lineOptions = {
				plugins: {
					legend: {
						labels: {
							color: '#ebedef'
						}
					}
				},
				scales: {
					x: {
						ticks: {
							color: '#ebedef'
						},
						grid: {
							color:  'rgba(160, 167, 181, .3)',
						}
					},
					y: {
						ticks: {
							color: '#ebedef'
						},
						grid: {
							color:  'rgba(160, 167, 181, .3)',
						}
					},
				}
			};
		}
	}
}
</script>