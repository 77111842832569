import api from './api';

export default class QuestionnaireService {
    getQuestionnnaireSubscriptions(id) {
        return api.get(`questionnaire/subscriptions/${id}`);
    }
    getCompletedUsers(id) {
        return api.get(`questionnaire/subscriptions/completedusers/${id}`);
    }
    getTimeData(id, year = 2023) {
        var url = `questionnaire/subscriptions/permonth/${id}`

        if (year) {
            url = url + `?year=${year}`;
        }

        return api.get(`${url}`);
    }
}
